
import Vue from "vue";
import BackIcon from "~/assets/svg/ic-db_navigation_close_32.svg?inline";
import CloseCrossIcon from "~/assets/svg/ic_db_navigation_close_cross_32.svg?inline";
import AccountIcon from "~/assets/svg/ic_db_account_24.svg?inline";
import MailIcon from "~/assets/svg/ic_db_mail_24.svg?inline";

export default Vue.extend({
  props: ["petitionID", "currentStep", "lastStep", "extraData"],
  components: { BackIcon, CloseCrossIcon, AccountIcon, MailIcon },
  data() {
    return {
      email: "",
      firstname: "",
      lastname: "",
      signPublic: false,
      acceptTOS: false,
      newsletter: false,
      signing: false,
      errors: {
        firstname: [],
        lastname: [],
        email: [],
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
      // wait until closing animation of modal is finished
      setTimeout(() => {
        this.resetForm();
      }, 250);
    },
    resetForm() {
      this.email = "";
      this.firstname = "";
      this.lastname = "";
      this.signPublic = false;
      this.newsletter = false;
      this.acceptTOS = false;
      this.errors.firstname = [];
      this.errors.lastname = [];
      this.errors.email = [];
    },
    validName: function (name) {
      const re = /^([^0-9]*)$/;
      return re.test(name);
    },
    validEmail: function () {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    validInput: function (input) {
      switch (input) {
        case "firstname":
          this.errors.firstname.pop();
          if (!this.firstname || !this.validName(this.firstname))
            this.errors.firstname.push(
              this.$t("petition.participation.step1.errors.firstname"),
            );
          break;
        case "lastname":
          this.errors.lastname.pop();
          if (!this.lastname || !this.validName(this.lastname))
            this.errors.lastname.push(
              this.$t("petition.participation.step1.errors.lastname"),
            );
          break;
        case "email":
          this.errors.email.pop();
          if (!this.email || !this.validEmail())
            this.errors.email.push(
              this.$t("petition.participation.step1.errors.email"),
            );
          break;
      }
    },
    registerNewsletter() {
      const body = new URLSearchParams();
      body.append("email", this.email);
      body.append("1244582", this.firstname);
      body.append("1244583", this.lastname);
      body.append("1244584", this.$i18n.locale);

      fetch(this.$t("petition.participation.step2.newsletter_endpoint"), {
        method: "POST",
        mode: "no-cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: body.toString(),
      });
    },
    handleSign: async function () {
      this.signing = true;

      if (this.newsletter) {
        this.registerNewsletter();
      }

      let result = await this.$directusApi.sendSignature({
        petition: this.petitionID,
        vorname: this.firstname,
        name: this.lastname,
        eMailAdresse: this.email,
        oeffentlich: this.signPublic ? "Ja" : "Nein",
        ...(this.extraData || {}),
      });

      let resultStatus = result.status;

      if (resultStatus === "ok" && this.$store.state.sea.gclid) {
        this.$directusApi.sendGoogleClickId({
          gclid: this.$store.state.sea.gclid,
          action: "Initiative",
          from_url: window.location.href,
        });
      }

      this.signing = false;

      if (resultStatus === "ok") {
        this.lastStep.headline = this.$t(
          "petition.participation.lastStep.success.headline",
        );
        this.lastStep.subheadline = this.newsletter
          ? this.$t(
              "petition.participation.lastStep.success.subheadlinenewsletter",
            )
          : this.$t("petition.participation.lastStep.success.subheadline");
      } else if (
        resultStatus === "error" &&
        result.errors.length &&
        result.errors[0].errorCode === "RECORD_NOT_UNIQUE"
      ) {
        this.lastStep.headline = this.$t(
          "petition.participation.lastStep.error.unique.headline",
        );
        this.lastStep.subheadline = this.$t(
          "petition.participation.lastStep.error.unique.subheadline",
        );
      } else {
        this.lastStep.headline = this.$t(
          "petition.participation.lastStep.error.nok.headline",
        );
        this.lastStep.subheadline = this.$t(
          "petition.participation.lastStep.error.nok.subheadline",
        );
      }

      this.lastStep.active = true;
    },
    triggerMatomoEvents: function (e) {
      Vue.trackMatomoEvents(e.target, this.$matomo);
    },
    trackCampaign() {
      if (this.$store.state.sea.gclid) {
        this.$matomo.trackEvent(
          "Initiative",
          "Track_Gclid",
          this.$store.state.sea.gclid,
        );
      }
    },
  },
  computed: {
    isCompleted() {
      return (
        this.firstname &&
        this.validName(this.firstname) &&
        this.lastname &&
        this.validName(this.lastname) &&
        this.email &&
        this.validEmail()
      );
    },
    hasErrors() {
      return (
        this.errors.firstname.length ||
        this.errors.lastname.length ||
        this.errors.email.length
      );
    },
  },
});
