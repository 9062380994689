
import Vue from "vue";
import FacebookIcon from "~/assets/svg/social/icon_facebook.svg?inline";
import TwitterIcon from "~/assets/svg/social/icon_twitter_blue.svg?inline";
import LinkedInIcon from "~/assets/svg/social/icon_linkedin.svg?inline";
import XingIcon from "~/assets/svg/social/icon_xing.svg?inline";
import MailIcon from "~/assets/svg/social/icon_mail.svg?inline";
import WebIcon from "~/assets/svg/social/icon_web.svg?inline";

export default Vue.extend({
  props: [
    "isShareModalVisible",
    "sharingLink",
    "matomoEventCategory",
    "matomoEventAction",
    "mailbody",
    "mailsubject",
    "sharingText",
  ],
  components: {
    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    XingIcon,
    MailIcon,
    WebIcon,
  },
  data() {
    return {
      showTextAreaToCopy: false,
    };
  },
  methods: {
    triggerMatomoEvents: function (e) {
      Vue.trackMatomoEvents(e.target, this.$matomo);
    },
    copyToClipboard() {
      this.showTextAreaToCopy = true;
      setTimeout(() => {
        this.$refs.sharingLinkTextArea.select();
        document.execCommand("copy");
        this.showTextAreaToCopy = false;
      }, 100);
    },
  },
});
