var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",class:[
    _vm.$store.state.page.dark ? 'page--dark' : '',
    _vm.$store.state.page.isNoah ? 'page--noah' : '',
    _vm.$store.state.page.isXmas ? 'page--xmas' : '',
    _vm.hydrated ? 'hydrated' : '',
    _vm.$nuxt.$route.name,
  ],attrs:{"data-cy":"outside"}},[_c('Header',{attrs:{"items":_vm.header}}),_vm._v(" "),_c('main',{staticClass:"page__content"},[_c('Nuxt'),_vm._v(" "),(_vm.$store.state.petition.data)?_c('PetitionModal',{attrs:{"isModalVisible":_vm.$store.state.petitionModal.isModalVisible,"petitionID":_vm.$store.state.petition.data.pk_id}}):_vm._e(),_vm._v(" "),(_vm.$store.state.petition.data)?_c('PetitionRevokeModal',{attrs:{"isModalVisible":_vm.$store.state.petitionRevokeModal.isModalVisible,"petitionID":_vm.$store.state.petition.data.pk_id}}):_vm._e(),_vm._v(" "),_c('ShareModal',{attrs:{"isShareModalVisible":_vm.$store.state.shareModal.isModalVisible,"sharingLink":_vm.$store.state.shareModal.sharingLink,"sharingText":_vm.$store.state.shareModal.sharingText,"mailbody":_vm.$store.state.shareModal.mailbody,"mailsubject":_vm.$store.state.shareModal.mailsubject,"matomoEventCategory":_vm.$store.state.shareModal.matomoEventCategory,"matomoEventAction":_vm.$store.state.shareModal.matomoEventAction}}),_vm._v(" "),_c('ConsentModal',{attrs:{"isConsentModalVisible":_vm.$store.state.consentModal.isModalVisible,"hideHeader":false,"isCloseable":true},on:{"consentGiven":_vm.loadAdobeLaunchTag}})],1),_vm._v(" "),_c('Footer',{attrs:{"title":this.$t('footer.title'),"subtitle":this.$t('footer.subtitle'),"social":_vm.footer.social,"navigation":_vm.footer.navigation}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }