
import Vue from "vue";
import BackIcon from "~/assets/svg/ic-db_navigation_close_32.svg?inline";
import CloseCrossIcon from "~/assets/svg/ic_db_navigation_close_cross_32.svg?inline";
import AccountIcon from "~/assets/svg/ic_db_account_24.svg?inline";
import MailIcon from "~/assets/svg/ic_db_mail_24.svg?inline";

export default Vue.extend({
  props: ["isModalVisible", "petitionID", "extraData"],
  components: { BackIcon, CloseCrossIcon, AccountIcon, MailIcon },
  data() {
    return {
      steps: [1, 2],
      currentStep: 1,
      lastStep: {
        active: false,
        headline: "",
        subheadline: "",
      },
      showWackenIntro: true,
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("petitionModal/closeModal");
      // wait until closing animation of modal is finished
      setTimeout(() => {
        this.currentStep = 1;
        this.lastStep = {
          active: false,
          headline: "",
          subheadline: "",
        };
      }, 250);
    },
    switchStep: function (index) {
      if (this.$refs.form.isCompleted) {
        this.currentStep = index;
      }
    },
    triggerMatomoEvents: function (e) {
      Vue.trackMatomoEvents(e.target, this.$matomo);
    },
  },
});
