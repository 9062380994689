export const state = () => ({
  hidden: false,
});

export const mutations = {
  toggle(state) {
    state.hidden = !state.hidden;
  },
  show(state) {
    state.hidden = false;
  },
  hide(state) {
    state.hidden = true;
  },
};
