
import Vue from "vue";
import ArrowDown from "~/assets/svg/ic-db_expand-more_24.svg?inline";

export default Vue.extend({
  props: ["isConsentModalVisible", "hideHeader", "isCloseable"],
  components: { ArrowDown },
  methods: {
    consentHandler: function (answer) {
      const lsConsentData = {
        consent: answer == 1,
        validToTimestamp: Date.now() + (365 / 2) * 24 * 60 * 60 * 1000,
      };

      localStorage.setItem("consent", JSON.stringify(lsConsentData));
      this.closeModal();

      if (answer == 1) this.$emit("consentGiven");
    },
    closeModal: function () {
      this.$store.commit("consentModal/closeModal");
    },
  },
});
