export const state = () => ({
  isModalVisible: false,
  matomoEventCategory: "",
  matomoEventAction: "",
  sharingLink: "",
  sharingText: "",
  mailbody: "",
  mailsubject: "",
});

export const mutations = {
  showModal(
    state,
    payload = {
      matomoEventCategory: "SharePetition",
      sharingLink: "https://wirsindgueter.de/",
      sharingText: "",
      mailbody: "",
      mailsubject: "",
    },
  ) {
    state.isModalVisible = true;
    state.matomoEventCategory = payload.matomoEventCategory;
    state.mailbody = payload.mailbody || "";
    state.mailsubject = payload.mailsubject || "";
    state.sharingLink = payload.sharingLink;
    state.sharingText = payload.sharingText || "";
    state.matomoEventAction = matomoEventActions[payload.matomoEventCategory];
  },
  closeModal(state) {
    state.isModalVisible = false;
  },
};

const matomoEventActions = {
  SharePetition: "Share_SupporterBadge_2",
  ShareCargoMontag: "Share_CargoMontag_2",
};
