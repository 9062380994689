
import CloseCrossIcon from "~/assets/svg/ic_db_navigation_close_cross_32.svg?inline";

export default {
  name: "modal",
  props: {
    dark: {
      default: false,
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    wide: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    isCloseable: {
      default: true,
      type: Boolean,
    },
    floatingHeader: {
      default: false,
      type: Boolean,
    },
  },
  components: { CloseCrossIcon },
  data: function () {
    return {
      randomId: Math.floor(Math.random() * (100 - 1 + 1)),
    };
  },
  mounted() {},
  methods: {
    closeModalEmit: function () {
      this.$emit("close");
    },
    preventClose: function (e) {
      e.stopPropagation();
    },
  },
};
