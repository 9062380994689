
export default {
  props: [
    "id",
    "title",
    "dark",
    "narrow",
    "narrowContent",
    "titleClass",
    "nofade",
    "flexContent",
    "whiteContent",
  ],
};
