
import Vue from "vue";
import BackIcon from "~/assets/svg/ic-db_navigation_close_32.svg?inline";
import CloseCrossIcon from "~/assets/svg/ic_db_navigation_close_cross_32.svg?inline";
import MailIcon from "~/assets/svg/ic_db_mail_24.svg?inline";

export default Vue.extend({
  props: ["isModalVisible", "petitionID"],
  components: { BackIcon, CloseCrossIcon, MailIcon },
  data() {
    return {
      steps: [1, 2],
      currentStep: 1,
      lastStep: {
        active: false,
        headline: "",
        subheadline: "",
      },
      email: "",
      errors: {
        email: [],
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("petitionRevokeModal/closeModal");
      // wait until closing animation of modal is finished
      setTimeout(() => {
        this.resetForm();
      }, 250);
    },
    resetForm() {
      this.currentStep = 1;
      this.lastStep = {
        active: false,
        headline: "",
        subheadline: "",
      };
      this.email = "";
      this.errors.email = [];
    },
    switchStep: function (event, index) {
      event.preventDefault();

      if (this.isCompleted) {
        this.currentStep = index;
      }
    },
    validName: function (name) {
      const re = /^([^0-9]*)$/;
      return re.test(name);
    },
    validEmail: function () {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    validInput: function (input) {
      if (input === "email") {
        this.errors.email.pop();

        if (!this.email || !this.validEmail())
          this.errors.email.push(this.$t("revoke.modal.step1.errors.email"));
      }
    },
    handleSign: async function () {
      let result = await this.$directusApi.revokeSignature({
        eMailAdresse: this.email,
      });
      let resultStatus = result.status;

      if (resultStatus === "ok") {
        this.lastStep.headline = this.$t(
          "revoke.modal.lastStep.success.headline",
        );
        this.lastStep.subheadline = this.$t(
          "revoke.modal.lastStep.success.subheadline",
        );
      } else {
        this.lastStep.headline = this.$t(
          "revoke.modal.lastStep.error.nok.headline",
        );
        this.lastStep.subheadline = this.$t(
          "revoke.modal.lastStep.error.nok.subheadline",
        );
      }

      this.lastStep.active = true;
    },
  },
  computed: {
    isCompleted() {
      return this.email && this.validEmail();
    },
    hasErrors() {
      return this.errors.email.length;
    },
  },
});
