// 24h * 6.5 days -> from monday 19:50 to the following monday 7:59
// add 0.15 hours to add the 9 remaining hours until 7:59
export const RAFFLE_TIME_IN_HOURS = 24 * 6.5;
export const ADDITIONAL_RAFFLE_TIME_IN_MINUTES = 9;
import { addSeconds } from "date-fns";

export const metaTags = (props: {
  title?: string;
  description?: string;
  ogtype?: string;
  ogurl?: string;
  ogimage?: string;
  robots?: string;
  site_name?: string;
  keywords?: string;
}) => {
  const {
    title,
    description,
    ogtype,
    ogurl,
    ogimage,
    robots,
    site_name,
    keywords,
  } = Object.assign(
    {
      title: "DB Cargo",
      description: "",
      ogtype: "website",
      ogurl: "",
      ogimage: "",
      robots: "",
      site_name: "DB Cargo",
      keywords: "DB Cargo, Güter, Initiative",
    },
    props,
  );
  return [
    {
      hid: "description",
      name: "description",
      content: description,
    },
    {
      hid: "robots",
      name: "robots",
      content: robots,
    },
    {
      hid: "keywords",
      name: "keywords",
      content: keywords,
    },
    {
      hid: "ogtitle",
      property: "og:title",
      content: title,
    },
    {
      hid: "ogimage",
      property: "og:image",
      content: ogimage,
    },
    {
      hid: "ogtype",
      property: "og:type",
      content: ogtype,
    },
    {
      hid: "ogurl",
      property: "og:url",
      content: ogurl,
    },
    {
      hid: "ogsite_name",
      property: "og:site_name",
      content: site_name,
    },
    {
      hid: "ogdescription",
      property: "og:description",
      content: description,
    },
    {
      hid: "twittercard",
      name: "twitter:card",
      content: "summary",
    },
    {
      hid: "twittertitle",
      name: "twitter:title",
      content: title,
    },
    {
      hid: "twitterdescription",
      name: "twitter:description",
      content: description,
    },
    {
      hid: "twitterimage",
      name: "twitter:image",
      content: ogimage,
    },
    {
      hid: "twittersite",
      name: "twitter:site",
      content: "db_cargo",
    },
  ];
};

export const pullEveryMinute = (callback: () => void) => {
  const ONE_MINUTE_IN_MILLIS = 60000;

  callback();

  return setInterval(() => {
    callback();
  }, ONE_MINUTE_IN_MILLIS);
};

let startDate = new Date();
let interval: NodeJS.Timer | null = null;

export const getCurrentDate = () => {
  let date = new Date();

  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const preview = params.get("preview");

    // timetravel to the set date in previewmode
    if (preview === "true") {
      if (params.get("date")) {
        date = new Date(`${params.get("date")}`);

        if (params.get("currentTime") == "true") {
          const now = new Date();
          date.setHours(now.getHours());
          date.setMinutes(now.getMinutes());
          date.setSeconds(now.getSeconds());
        } else {
          if (!interval) {
            interval = setInterval(() => {
              startDate = addSeconds(startDate, 1);
            }, 1000);
          } else {
            date = startDate;
          }
        }

        startDate = date;
      }
    }
  }

  return date;
};
