import { TGewichthebenEntry, TGewichthebenOptOut } from "./../types";
import { Plugin } from "@nuxt/types";
import {
  TGoogleClickId,
  TNews,
  TPerson,
  TPetition,
  TRaffle,
  TRaffleEntry,
  TSignature,
  TTopics,
  TXmasEntry,
} from "../types";
import { Directus } from "@directus/sdk";
import locales from "~/config/locales";
import compareAsc from "date-fns/compareAsc";
import sub from "date-fns/sub";
import { ICohuApi } from "./cohuapi-type";
import {
  ADDITIONAL_RAFFLE_TIME_IN_MINUTES,
  RAFFLE_TIME_IN_HOURS,
} from "~/lib/utils";

const directusApi: Plugin = (ctx, inject) => {
  const baseHeaders = (headers = {}) => {
    return {
      accept: "application/json",
      ...headers,
    };
  };

  const responseToJson = (response: any) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  };

  class DirectusApiImpl implements ICohuApi {
    localeMapping = Object.values(locales).reduce((mapping, locale: any) => {
      mapping[locale.code] = locale;
      return mapping;
    }, {});

    localeCode = "de";
    locale = this.localeMapping[this.localeCode];
    baseUrl = ctx.env.generateUrl;
    assetPath = ctx.env.baseUrl;
    mode = ctx.env.mode;
    directus;

    constructor(locale = "de") {
      this.localeCode = locale;
      this.locale = this.localeMapping[locale];

      if (typeof window !== "undefined") {
        this.baseUrl = ctx.env.baseApiUrl;
      }

      this.directus = new Directus(this.baseUrl);
    }

    setLocale(locale = "de") {
      this.localeCode = locale;
      this.locale = this.localeMapping[locale];
    }

    imagePath(name: string, cropSettings: any) {
      if (!name) {
        return "";
      }

      let url = `${ctx.env.baseApiUrl}/assets/${name}`;
      let params = new URLSearchParams();

      if (cropSettings) {
        Object.keys(cropSettings).forEach((key) => {
          params.append(key, cropSettings[key]);
        });
      }

      params.append("format", "webp");

      return `${url}?${params.toString()}`;
    }

    getStatements(direction: "asc" | "desc" = "desc") {
      return this.directus
        .items("ExternerContent")
        .readMany({
          sort: [`${direction === "asc" ? "-" : ""}ablaufdatum`],
          fields: ["*"],
          filter: {
            language: {
              code: {
                _eq: this.locale.iso,
              },
            },
          },
        })
        .then((result) => result.data);
    }

    getSignatureCount(): Promise<number> {
      return fetch(`${this.baseUrl}/api`)
        .then(responseToJson)
        .then((data) => {
          let count = 0;

          if (data.count) {
            count = parseInt(data.count);
          }
          return count;
        });
    }

    getLatestPeople(): Promise<Array<TPerson>> {
      return this.directus
        .items("PetitionUnterschriften")
        .readMany({
          limit: 3,
          sort: ["-datum"],
          fields: ["vorname", "name", "datum", "date_created"],
          filter: {
            oeffentlich: {
              _eq: "Ja",
            },
          },
        })
        .then((result) =>
          result.data.map((item) => ({
            name: `${item.vorname} ${item.name}`,
            date: new Date(item.date_created).getTime(),
          })),
        );
    }

    getNews({
      limit,
      page,
      isPreview = false,
    }: { limit?: number; page?: number; isPreview?: boolean } = {}): Promise<
      Array<TNews>
    > {
      const filter: any = {
        language: {
          code: {
            _eq: this.locale.iso,
          },
        },
      };

      if (!isPreview) {
        filter.status = {
          _eq: "published",
        };
      }

      return this.directus
        .items("News")
        .readMany({
          meta: "filter_count",
          limit: limit,
          page: page || 1,
          sort: ["-ausspielzeitraumStart"],
          fields: [
            "ausspielzeitraumStart",
            "pk_id",
            "titel",
            "teaser",
            "titelbild.id",
            "titelbild.copyright",
            "slug",
          ],
          filter,
        })
        .then((result) => ({
          items: result.data,
          count: result.meta.filter_count,
        }));
    }

    getTopics(isPreview = false): Promise<Array<TTopics>> {
      const filter: any = {
        language: {
          code: {
            _eq: this.locale.iso,
          },
        },
      };

      if (!isPreview) {
        filter.status = {
          _eq: "published",
        };
      }

      return this.directus
        .items("Topics")
        .readMany({
          meta: "filter_count",
          sort: ["sort", "-ausspielzeitraumStart"],
          fields: [
            "ausspielzeitraumStart",
            "pk_id",
            "titel",
            "teaserTitle",
            "teaser",
            "metadescription",
            "titelbild.id",
            "titelbild.copyright",
            "slug",
          ],
          filter,
        })
        .then((result) => ({
          items: result.data.map((item) => ({
            ausspielzeitraumStart: item.ausspielzeitraumStart,
            pk_id: item.pk_id,
            titel: item.titel,
            teaserTitle: item.teaserTitle,
            teaser: item.teaser,
            titelbild: item.titelbild,
            slug: item.slug,
          })),
          count: result.meta.filter_count,
        }));
    }

    async getTranslations(id: string, contentType = "News") {
      const relatedArticleIds = await this.directus
        .items(contentType)
        .readMany({
          filter: {
            _and: [
              {
                translationMapping: {
                  [`${contentType}_pk_id`]: {
                    _in: id,
                  },
                },
              },
            ],
          },
          fields: [`translationMapping.related_${contentType}_pk_id.*`],
        });

      if (relatedArticleIds?.data) {
        return relatedArticleIds?.data[0]?.translationMapping.map(
          (item) => item[`related_${contentType}_pk_id`],
        );
      }

      return [];
    }

    getArticle(
      slug: string,
      id: string,
      collectionName: string = "News",
      isPreview: boolean = false,
    ): Promise<Array<TNews>> {
      const filter: any = {
        _and: [
          {
            slug: {
              _eq: slug,
            },
            pk_id: {
              _eq: id,
            },
          },
          {
            language: {
              code: {
                _eq: this.locale.iso,
              },
            },
          },
        ],
      };

      if (!isPreview) {
        filter._and.push({
          status: {
            _eq: "published",
          },
        });
      }

      return this.directus
        .items(collectionName)
        .readMany({
          limit: 1,
          fields: ["*", "titelbild.id", "titelbild.copyright"],
          filter,
        })
        .then((result) => {
          const d = result.data.map((item) => {
            let text = item.volltext.replace(
              /src="[^"]*\/assets/g,
              `src="${this.assetPath}/assets`,
            );

            // Find images, extract the width and height and set it into the html
            const imgRegex =
              /<img src="[^"]*(width=\d+)&amp;(height=\d+)[^"]*"/gim;
            let match;

            while ((match = imgRegex.exec(text)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (match.index === imgRegex.lastIndex) {
                imgRegex.lastIndex++;
              }

              // match[0] full img tag
              // match[1] width
              // match[2] height
              if (match.length === 3) {
                text = text.replace(
                  match[0],
                  `${match[0]} ${match[1].replace(
                    "=",
                    '="',
                  )}" ${match[2].replace("=", '="')}" loading="lazy"`,
                );
              }
            }

            return {
              ...item,
              teaserTitle: item.teaserTitle || item.titel,
              teaser: (item.teaser || "").substring(0, 26) + "…",
              volltext: text,
              image: {
                md: {
                  image: this.imagePath(item.titelbild.id, { width: 375 }),
                  image2x: this.imagePath(item.titelbild.id, { width: 750 }),
                },
                lg: {
                  image: this.imagePath(item.titelbild.id, { width: 780 }),
                  image2x: this.imagePath(item.titelbild.id, { width: 1560 }),
                },
              },
            };
          });

          return d;
        });
    }

    getPetition(includeChilds = false): Promise<TPetition | {} | null> {
      const fields = ["*"];

      if (includeChilds) {
        fields.push("meilensteine.*.*", "gruende.*.*");
      }

      return this.directus
        .items("Petition")
        .readMany({
          limit: 1,
          fields: fields,
          filter: {
            language: {
              code: {
                _eq: this.locale.iso,
              },
            },
          },
        })
        .then((result) => {
          if (!result.data.length) {
            return null;
          }

          const petition = result.data[0];
          if (includeChilds) {
            petition.faq = petition.gruende
              .filter((g) => g.type === "FAQ")
              .sort(
                (a, b) =>
                  parseInt(a.ranking || "0") - parseInt(b.ranking || "0"),
              );
            petition.forderung = petition.gruende
              .filter((g) => g.type === "Forderung")
              .sort(
                (a, b) =>
                  parseInt(a.ranking || "0") - parseInt(b.ranking || "0"),
              );
            petition.gruende = petition.gruende
              .filter((g) => g.type === "Grund")
              .sort(
                (a, b) =>
                  parseInt(a.ranking || "0") - parseInt(b.ranking || "0"),
              );

            petition.meilensteine = petition.meilensteine.sort(
              (a, b) =>
                (new Date(b.meilensteindatum) as any) -
                (new Date(a.meilensteindatum) as any),
            );
          }

          return petition;
        });
    }

    async sendSignature(signatureData: TSignature) {
      const signatureItem = this.directus.items("PetitionUnterschriften");
      signatureData.language = this.locale.iso;

      const result: { errors: Array<any>; status: string } = {
        errors: [],
        status: "error",
      };

      try {
        await signatureItem.createOne({
          ...signatureData,
        });
        result.status = "ok";
      } catch (e: any) {
        if (e.errors.length) {
          result.errors = [{ errorCode: e.errors[0].extensions.code }];
        }
      }

      return result;
    }

    async sendGoogleClickId(gclidData: TGoogleClickId) {
      const gclidItems = this.directus.items("GoogleClickIdentifier");

      const result: { errors: Array<any>; status: string } = {
        errors: [],
        status: "error",
      };

      try {
        await gclidItems.createOne(gclidData);
        result.status = "ok";
      } catch (e: any) {
        if (e.errors.length) {
          result.errors = [{ errorCode: e.errors[0].extensions.code }];
        }
      }

      return result;
    }

    getGoogleClickIds(): Promise<Array<TGoogleClickId>> {
      return this.directus.items("GoogleClickIdentifier").readMany();
    }

    async getRaffles(
      getAll = false,
      currentYear = "",
    ): Promise<Array<TRaffle>> {
      let raffles = [];
      try {
        raffles = await this.directus
          .items("Gewinnspiel1")
          .readMany({
            fields: ["*", "gewinne.*.*"],
            filter: {
              language: {
                code: {
                  _eq: this.locale.iso,
                },
              },
            },
          })
          .then((result) => {
            if (!result || !result.data) {
              return [];
            }

            let items = result.data
              .reduce((raffles, raffle) => {
                raffle.prizes = raffle.gewinne
                  .map((g) => g.GewinnspielGewinne_id)
                  .filter((g) => g !== null)
                  .sort(
                    (a, b) =>
                      parseInt(a.position || "0") - parseInt(b.position || ""),
                  )
                  .map((prize) => {
                    if (prize.gewinnbild) {
                      const imagename = prize.gewinntitel
                        .toLowerCase()
                        .replace(/[^a-z_0-9\-]/g, "");

                      prize.image = {
                        md: {
                          teaser: `/prizes/${imagename}/146.png`,
                          teaser2x: `/prizes/${imagename}/292.png`,
                          image2x: `/prizes/${imagename}/622.png`,
                          image: `/prizes/${imagename}/311.png`,
                        },
                        lg: {
                          teaser: `/prizes/${imagename}/220.png`,
                          teaser2x: `/prizes/${imagename}/440.png`,
                          image2x: `/prizes/${imagename}/1880.png`,
                          image: `/prizes/${imagename}/940.png`,
                        },
                      };
                    }
                    return prize;
                  });

                if (raffle.steps) {
                  try {
                    raffle.words = JSON.parse(raffle.steps);
                  } catch (e) {
                    console.error(e);
                  }
                }

                raffle.steps = [];

                raffle.date = new Date(raffle.ausspieldatum);

                for (let i = 1; i < 5; i++) {
                  if (raffle[`step${i}`]) {
                    raffle.steps.push({
                      title: raffle[`step${i}`],
                      text: raffle[`erklaerung${i}`],
                    });
                  }
                }

                const olderThanOneDay = sub(new Date(), {
                  hours: RAFFLE_TIME_IN_HOURS,
                  minutes: ADDITIONAL_RAFFLE_TIME_IN_MINUTES,
                });

                // remove raffles which are in the past more than one day
                if (compareAsc(raffle.date, olderThanOneDay) < 0 && !getAll) {
                  return raffles;
                }

                raffles.push(raffle);

                return raffles;
              }, [])
              .sort((a: { date: string }, b: { date: string }) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });

            if (currentYear) {
              items.filter((item) => item.date.getFullYear() === currentYear);
            }

            return items;
          });
      } catch (e) {
        console.error(e);
      }

      return raffles;
    }

    addGewichthebenEntry(entry: TGewichthebenEntry) {
      return fetch(`${this.baseUrl}/api/participant`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify(
          Object.assign({}, entry, {
            language: this.localeCode === "de" ? "de-DE" : "en-GB",
            // TODO: Implement captcha
            "h-captcha-response": "30000000-aaaa-bbbb-cccc-000000000003",
          }),
        ),
      })
        .then(responseToJson)
        .catch(() => {
          return {
            status: {
              status: "servererror",
            },
          };
        });
    }

    addRaffleEntry(entry: TRaffleEntry) {
      return fetch(`${this.baseUrl}/monday`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify(
          Object.assign({}, entry, { lang: this.localeCode }),
        ),
      })
        .then(responseToJson)
        .catch(() => {
          return {
            status: {
              status: "servererror",
            },
          };
        });
    }

    addXmasEntry(entry: TXmasEntry) {
      return fetch(`${this.baseUrl}/xmas/participant`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify(
          Object.assign({}, entry, { lang: this.localeCode }),
        ),
      })
        .then(responseToJson)
        .catch(() => {
          return {
            status: {
              status: "servererror",
            },
          };
        });
    }

    validateRaffle(code: string) {
      return fetch(`${this.baseUrl}/monday/validate/${code}`, {
        method: "PATCH",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify({ code }),
      })
        .then(responseToJson)
        .catch(() => {
          return {
            status: "error",
          };
        });
    }

    async validateMail(id: string) {
      const signatureItem = this.directus.items("PetitionUnterschriften");
      const item = await signatureItem.readMany({
        limit: 1,
        fields: ["pk_id"],
        filter: {
          fromid: {
            _eq: id,
          },
        },
      });

      const result: { errors: Array<any>; status: string } = {
        errors: [],
        status: "error",
      };

      if (item.data.length) {
        try {
          await signatureItem.updateOne(item.data[0].pk_id, {
            fromValid: true,
            fromid: "",
          });
          result.status = "ok";
        } catch (e: any) {
          if (e.errors.length) {
            result.errors = [{ errorCode: e.errors[0].extensions.code }];
          }
        }
      }

      return result;
    }

    validateXmas(code: string) {
      return fetch(`${this.baseUrl}/xmas/validate/${code}`, {
        method: "PATCH",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify({ code }),
      })
        .then(responseToJson)
        .catch(() => {
          return {
            status: "error",
          };
        });
    }

    validateSignatureRemoval(code: string) {
      return fetch(`${this.baseUrl}/mail/${code}`, {
        method: "DELETE",
        headers: baseHeaders(),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.text();
        })
        .then(() => {
          return {
            status: "ok",
          };
        })
        .catch(() => {
          return {
            status: "error",
          };
        });
    }

    validateSignature(token: string) {
      return fetch(`${this.baseUrl}/mail/opt-in`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify({ token }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.text();
        })
        .then(() => {
          return { status: "ok" };
        })
        .catch(() => {
          return {
            status: "error",
          };
        });
    }

    revokeSignature(signature: TSignature) {
      return fetch(`${this.baseUrl}/mail/opt-out`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify(
          Object.assign({}, signature, {
            lang: this.locale.iso,
            collection: "PetitionUnterschriften",
          }),
        ),
      })
        .then((response: any) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.text();
        })
        .then(() => {
          return {
            errors: [],
            status: "ok",
          };
        })
        .catch(() => {
          return {
            errors: [],
            status: "error",
          };
        });
    }

    revokeSingleImage(id: string) {
      return fetch(`${this.baseUrl}/api/participant/${id}/image`, {
        method: "DELETE",
        headers: baseHeaders(),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.text();
        })
        .then(() => {
          return {
            status: "ok",
          };
        })
        .catch(() => {
          return {
            status: "error",
          };
        });
    }

    revokeAllImages(optout: TGewichthebenOptOut) {
      return fetch(`${this.baseUrl}/participant/opt-out`, {
        method: "POST",
        headers: baseHeaders({
          "content-type": "application/json",
        }),
        body: JSON.stringify(
          Object.assign({}, optout, {
            lang: this.locale.iso,
            collection: "PetitionUnterschriften",
          }),
        ),
      })
        .then((response: any) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.text();
        })
        .then(() => {
          return {
            errors: [],
            status: "ok",
          };
        })
        .catch(() => {
          return {
            errors: [],
            status: "error",
          };
        });
    }

    getBaseUrl() {
      return this.assetPath;
    }
  }

  const api = new DirectusApiImpl(ctx.app.i18n.locale);

  ctx.app.i18n.onLanguageSwitched = (_, newLocale) => {
    api.setLocale(newLocale);
    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  inject("directusApi", api);
};

export default directusApi;
