
import Vue from "vue";
import MenuIcon from "~/assets/svg/ic-db_menu_32.svg?inline";
import MenuClose from "~/assets/svg/ic-db_close_24.svg?inline";

export default Vue.extend({
  props: ["items"],
  components: { MenuIcon, MenuClose },
  data() {
    return {
      open: false,
      translations: [],
    };
  },
  methods: {
    isActiveMenuItem: function (item) {
      let isActive = false;
      if (
        item.path === "cargomontag" &&
        this.$route.path === "/confirmation/gewinnspiel"
      ) {
        isActive = true;
      }

      if (
        item.path === "gueter-informiert" &&
        this.$route.path.includes("gueter-informiert")
      ) {
        isActive = true;
      }

      return isActive;
    },
    toggleMenu: function () {
      this.open = !this.open;
      document.body.classList.toggle("overflow-hidden");

      if (this.open) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
    closeMenu: function () {
      this.open = false;
      document.body.classList.remove("overflow-hidden");
      this.$emit("close");
    },
    triggerMatomoEvents: function (e) {
      Vue.trackMatomoEvents(e.target, this.$matomo);
    },
    getLanguagePath(langCode: string) {
      if (this.translations?.length) {
        const filteredTranslation = this.translations.filter(
          (translationItem) =>
            translationItem?.language?.slice(0, 2) === langCode,
        );

        if (filteredTranslation.length) {
          let path = "article";
          if (this.$route.name?.startsWith("gueter-informiert")) {
            path = langCode === "de" ? "gueter-informiert" : "mas-informacion";
          }

          return `${
            langCode !== "de" ? `/${langCode}` : ""
          }/${path}/${filteredTranslation[0]?.slug}/${filteredTranslation[0]
            ?.pk_id}`;
        }
      }

      return this.switchLocalePath(langCode);
    },
  },
  async mounted() {
    if (
      (this.$route.name?.startsWith("article") ||
        this.$route.name?.startsWith("gueter-informiert")) &&
      this.$route.params.id
    ) {
      try {
        this.translations = await this.$directusApi.getTranslations(
          this.$route.params.id,
          this.$route.name?.startsWith("gueter-informiert") ? "Topics" : "News",
        );
      } catch (e) {}
    }
  },
});
