export const state = () => ({
  data: {
    pk_id: "",
  },
});

export const mutations = {
  setData(state, data) {
    state.data = data;
  },
};
