export const state = () => ({
  dark: false,
  isNoah: false,
  isXmas: false,
  hasFooterSpacing: true,
});

export const mutations = {
  isDark(state) {
    state.dark = true;
    state.isNoah = false;
    state.isXmas = false;
  },
  isNoah(state) {
    state.isNoah = true;
  },
  isXmas(state) {
    state.isXmas = true;
  },
  isLight(state) {
    state.dark = false;
    state.isNoah = false;
    state.isXmas = false;
  },
  hasFooterSpacing(state, payload) {
    state.hasFooterSpacing = payload;
  },
};
