
import Vue from "vue";
import { metaTags } from "../lib/utils";

export default {
  data: function () {
    return {
      petition: {},
      header: {
        language: [
          { title: "EN", url: "/", tooltip: this.$t("menu.language.english") },
          { title: "DE", url: "/", tooltip: this.$t("menu.language.german") },
        ],
        navigation: [
          {
            id: "1",
            title: this.$t("menu.navigation.news"),
            url: "/",
            path: "/",
          },
          {
            id: "2",
            title: this.$t("menu.navigation.landing"),
            url: "/wirsindgueter",
            path: "wirsindgueter",
          },
          {
            id: "3",
            title: this.$t("menu.navigation.initiative"),
            url: "/initiative/",
            path: "initiative",
          },
          {
            id: "4",
            title: this.$t("menu.navigation.topics"),
            url: "/gueter-informiert/",
            path: "gueter-informiert",
            disabled: this.$i18n.locale === "en",
          },
          {
            id: "6",
            title: this.$t("menu.navigation.aboutus"),
            url: "/sobrenosotros/",
            path: "sobrenosotros",
            disabled: this.$i18n.locale == "de" || this.$i18n.locale == "en",
          },
          {
            id: "5",
            title: this.$t("menu.navigation.galeria"),
            url: "/galeria/",
            path: "galeria",
            disabled: this.$i18n.locale == "de" || this.$i18n.locale == "en",
          },
          {
            id: "7",
            title: "Festivals",
            url: "/festivals",
            path: "/festivals",
            disabled: this.$i18n.locale !== "de",
          },
        ],
      },
      footer: {
        navigation: [
          [
            {
              title: this.$t("footer.navigation.legalTitle"),
              url: this.localePath("/impressum"),
            },
            {
              title: this.$t("footer.navigation.privatpolicyTitle"),
              url: this.localePath("/datenschutz"),
            },
            {
              title: this.$t("footer.navigation.revokeTitle"),
              click: () => this.$store.commit("petitionRevokeModal/showModal"),
            },
            {
              title: this.$t("footer.navigation.websiteTitle"),
              url: this.$t("footer.navigation.websiteLink"),
              target: "_blank",
            },
            {
              title: this.$t("consent.managelink"),
              click: () => this.$store.commit("consentModal/showModal"),
            },
          ],
          [
            {
              title: this.$t("menu.navigation.monday"),
              url: this.localePath("/cargomontag"),
              matomoCategory: "SelectCargoMontag",
              matomoAction: "Header_Home",
              matomoName: "Störer",
              disabled: this.$i18n.locale == "es",
            },
            {
              id: "5",
              title: this.$t("menu.navigation.flyingtrucks"),
              url: this.localePath("/flying-trucks"),
              path: "/flying-trucks",
            },
          ],
        ],
        social: [
          {
            title: "Facebook",
            url: this.$t("footer.social.urlFacebook"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "Facebook",
          },
          {
            title: "Instagram",
            url: this.$t("footer.social.urlInstagram"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "Instagram",
          },
          {
            title: "Twitter",
            url: this.$t("footer.social.urlTwitter"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "Twitter",
            disabled: this.$i18n.locale == "es",
          },
          {
            title: "Xing",
            url: this.$t("footer.social.urlXing"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "Xing",
            disabled: this.$i18n.locale == "es",
          },
          {
            title: "LinkedIn",
            url: this.$t("footer.social.urlLinkedin"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "LinkedIn",
          },
          {
            title: "Youtube",
            url: this.$t("footer.social.urlYoutube"),
            matomoCategory: "SelectSocialMedia",
            matomoAction: "SM_Icon_Footer",
            matomoName: "Youtube",
            disabled: this.$i18n.locale !== "es",
          },
        ],
      },
      hydrated: false,
    };
  },
  head() {
    const i18n = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      ...i18n,
      meta: [
        ...metaTags({
          ogurl: this.$directusApi.getBaseUrl() + this.$route.fullPath,
        }),
        ...i18n.meta,
      ],
    };
  },
  mounted() {
    if (this.$route.query.gclid) {
      this.$store.commit("sea/setGclid", this.$route.query.gclid);
    }
    this.trackingHandler();

    this.$nextTick(() => {
      this.hydrated = true;
    });
  },
  methods: {
    trackingHandler: function () {
      this.$router.afterEach(() => {
        this.trackPageReady();
      });
      const hideOnPages =
        this.$route.fullPath === "/datenschutz" ||
        this.$route.fullPath === "/impressum";
      if (!this.isConsentValid() && !hideOnPages) {
        this.$nextTick(() => {
          this.$store.commit("consentModal/showModal");
        });
      }
      if (this.isConsentValid()) {
        this.loadAdobeLaunchTag();
      }
    },
    loadAdobeLaunchTag: function () {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://assets.adobedtm.com/8d585d62362a/e589d6efd73a/launch-17ba209a8255.min.js";
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        this.trackPageReady();
      });
    },
    isConsentValid: function () {
      const lsConsentData = localStorage.getItem("consent");

      return (
        lsConsentData &&
        Date.now() <= JSON.parse(lsConsentData).validToTimestamp &&
        JSON.parse(lsConsentData).consent
      );
    },
    trackPageReady: function () {
      if (this.isConsentValid()) {
        window.digitalData = {
          site: {
            partner: "ECM",
            project: "Corporate Site",
            environment: "prod",
          },
          page: {
            category: "content",
            name: this.$router.currentRoute.name,
            date: new Date().toISOString().split("T")[0],
            breadcrumb: "",
            language: this.$i18n.locale,
          },
        };

        window._satellite.track("page_ready", { details: "page ready" });
      }
    },
  },
};

Vue.trackMatomoEvents = (targetElement, matomo) => {
  let matomoCategory = targetElement.getAttribute("data-matomo-category");
  if (matomoCategory === null) {
    matomoCategory = "";
  }

  let matomoAction = targetElement.getAttribute("data-matomo-action");
  if (matomoAction === null) {
    matomoAction = "";
  }

  let matomoName = targetElement.getAttribute("data-matomo-name");
  if (matomoName === null) {
    matomoName = "";
  }

  let matomoValue = targetElement.getAttribute("data-matomo-value");
  if (matomoValue === null) {
    matomoValue = "";
  }
  if (matomo && matomoCategory && matomoAction) {
    matomo.trackEvent(matomoCategory, matomoAction, matomoName, matomoValue);
  }
};
