export const state = () => ({
  isModalVisible: false,
});

export const mutations = {
  showModal(state) {
    state.isModalVisible = true;
  },
  closeModal(state) {
    state.isModalVisible = false;
  },
};
