
import Vue from "vue";

function applyTransition(node, animationClass, baseClass) {
  animationClass = animationClass.split(" ");
  baseClass = baseClass.split(" ");
  node.classList.add(...animationClass);
}

export default Vue.extend({
  name: "Observer",
  props: {
    threshold: {
      type: Number,
      default: 0.1,
    },
    root: {
      type: Object,
      default: () => null,
    },
    rootMargin: {
      type: String,
      default: () => "0px 0px 0px 0px",
    },
    animationClass: {
      type: String,
    },
    baseClass: {
      type: String,
    },
  },
  mounted() {
    if (!this.$slots.default) {
      return;
    }

    let el = this.$slots.default[0].elm;

    if (el && this.baseClass) {
      el.classList.add(...this.baseClass.split(" "));
    }

    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.$emit("transitionstart", entry);

            if (this.animationClass) {
              applyTransition(
                entry.target,
                this.animationClass,
                this.baseClass,
              );
            }
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: this.threshold,
        root: this.root,
        rootMargin: this.rootMargin,
      },
    );
    this.observer.observe(el);
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  render() {
    return this.$slots.default ? this.$slots.default[0] : null;
  },
});
