
import Vue from "vue";

export default Vue.extend({
  props: ["navigation", "social", "title", "subtitle"],
  methods: {
    triggerMatomoEvents: function (e) {
      Vue.trackMatomoEvents(e.target.closest("a"), this.$matomo);
    },
  },
});
