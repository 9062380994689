export default [
  {
    code: "en",
    name: "English",
    iso: "en-US",
    id: 1,
  },
  {
    code: "de",
    name: "Deutsch",
    iso: "de-DE",
    id: 1607343782547,
  },
  {
    code: "es",
    name: "Español",
    iso: "es-ES",
    id: 1633508536384,
  },
];
