
import Vue from "vue";
import Logo from "~/assets/svg/logo.svg?inline";
import lerp from "lerp";

export default Vue.extend({
  props: ["items"],
  components: { Logo },
  data: () => ({
    rafId: 0,
  }),
  methods: {
    menuOpen() {
      if (this.rafId) {
        window.cancelAnimationFrame(this.rafId);
      }

      if (this.$refs.header) {
        (this.$refs.header as HTMLDivElement).style.transform = "none";
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      let lastY = window.scrollY;
      const thresholdIn = 100;
      const thresholdOut = 50;
      let target = 0;
      let y = 0;

      if (!this.$refs.header) {
        return;
      }

      const animate = () => {
        if (target != Math.round(y)) {
          this.rafId = requestAnimationFrame(animate);
        }

        y = lerp(y, target, 0.1);

        if (target == Math.round(y)) {
          y = target;
        }

        if (this.$refs.header) {
          (
            this.$refs.header as HTMLDivElement
          ).style.transform = `translateY(${y}%)`;
        }
      };

      window.addEventListener("scroll", () => {
        const currentY = window.scrollY;
        const delta = currentY - lastY;

        if (delta > thresholdIn) {
          lastY = currentY;
          target = -100;
          this.rafId = requestAnimationFrame(animate);
        }

        if (delta < 0 && Math.abs(delta) > thresholdOut) {
          lastY = currentY;
          target = 0;
          this.rafId = requestAnimationFrame(animate);
        }
      });
    });
  },
  beforeDestroy: function () {
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
  },
});
