export const state = () => ({
  isModalVisible: false,
});

export const mutations = {
  showModal(state, showIt = true) {
    state.isModalVisible = showIt;
  },
  closeModal(state) {
    state.isModalVisible = false;
  },
};
